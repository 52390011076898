import { useEffect, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { getFirebaseTokenFromPlatform } from '../../../infra/auth';
import Loader from '../../../shared/components/atoms/loader';
import { NotificationsProvider } from '../../../shared/components/molecules/notifications-provider';
import {
  AppProtectedRoutes,
  AppUnprotectedRoutes,
} from '../routes/auth-routes';
import { MUI_LICENCE_KEY } from '../../../shared/constants';

import { LicenseInfo } from '@mui/x-license-pro';
import { ComponentVariables_ } from '../../builder/typings/bot-data';
import platform from '../../../infra/platform';
LicenseInfo.setLicenseKey(MUI_LICENCE_KEY);

interface CustomEventMap {
  focusNodeId: CustomEvent<{ nodeId: string }>;
  openCarouselCard: CustomEvent<{ nodeId: string; cardIndex: number }>;
  zoomCanvas: CustomEvent;
  reloadpagedata: CustomEvent<{ refreshType: string }>;
  nodeMouseEvent: CustomEvent<{
    type: string;
    nodeId: string;
  }>;
  removeNodeVariables: CustomEvent<ComponentVariables_>;
}

declare global {
  interface Document {
    //adds definition to Document
    addEventListener<K extends keyof CustomEventMap>(
      type: K,
      listener: (this: Document, ev: CustomEventMap[K]) => void
    ): void;
    removeEventListener<K extends keyof CustomEventMap>(
      type: K,
      listener: (this: Document, ev: CustomEventMap[K]) => void
    ): void;
  }
}

const App = () => {
  const [isAuthReady, setIsAuthReady] = useState(false);

  useEffect(() => {
    getFirebaseTokenFromPlatform().then(() => {
      setIsAuthReady(true);
    });
    platform.getOrigin();
    if (typeof window !== 'undefined') {
      const urlParams = new URLSearchParams(window.location.search);
      const parentOrigin = urlParams.get('parent-origin');
      const decodedOrigin = decodeURIComponent(parentOrigin || '');
      if (decodedOrigin) {
        localStorage.setItem('parent-origin', decodedOrigin);
      }    
    }
  }, []);

  if (isAuthReady) {
    return (
      <>
        <DndProvider backend={HTML5Backend}>
          <NotificationsProvider maxNotifications={5} />
          <AppProtectedRoutes />
        </DndProvider>
      </>
    );
  }

  if (!isAuthReady) {
    return <AppUnprotectedRoutes />;
  }

  return <Loader size={32} secondary={'Loading app...'} />;
};

export default App;
